import React from "react"
import Seo from "../../../components/seo"

import ShockFreese from "./shokovaya-zamorozka"


const ShockFreeseIndex = () => (

  <>
    <Seo 
      title="Камеры шоковой заморозки и оборудование шоковой заморозки."
      description="Производим и являемся партнерами ведущих европейских и китайских производителей оборудования для шоковой заморозки различных продуктов."
      keywords="шоковая, заморозка, оборудование заморозки, камеры заморозки шоковой, спиральный морозильный аппарат"
    />
    <ShockFreese />
  </>
  
)

export default ShockFreeseIndex